import { PublicClientApplication } from '@azure/msal-browser';
import { config } from './Config';

export const authorityDomain = `${config.tenant}.b2clogin.com`;

export const configuration = {
	auth: {
		clientId: config.clientId,
		authority: `https://${authorityDomain}/${config.b2cTenant}/${config.b2cSigningPolicy}`,
		knownAuthorities: [authorityDomain],
		redirectUri: config.redirectUrl,
		postLogoutRedirectUri: config.redirectUrl
	},
	cache: {
		cacheLocation: 'sessionStorage'
	}
};

export const authInstance = new PublicClientApplication(configuration);

export const loginRequest = {
    account: authInstance.getAllAccounts(),
    scopes: [config.scope]
};