export const config = {
	tenant: process.env.REACT_APP_TENANT,
	authority: process.env.REACT_APP_AUTHORITY,
	b2cTenant: process.env.REACT_APP_B2C_TENANT,
	clientId: process.env.REACT_APP_CLIENT_ID,
	b2cSigningPolicy: process.env.REACT_APP_B2C_SIGNIN_POLICY,
	redirectUrl: process.env.REACT_APP_REDIRECT_URL,
	scope: process.env.REACT_APP_SCOPE,
	baseUrl: process.env.REACT_APP_BASE_URL,
	subscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY
};